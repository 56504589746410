<template>
  <div>
    <slot name="prefix">(</slot>
    <span v-if="event.hasCompulsories">Figures</span>
    <span v-if="event.hasCompulsories && (event.hasRoutine || event.hasBallet)">+</span>
    <span v-if="event.hasCompulsories && !event.hasRoutine && !event.hasBallet"> seules</span>

    <span v-if="event.hasRoutine">Routine</span>
    <span v-if="event.hasRoutine && event.hasBallet">+</span>
    <span v-if="event.hasRoutine && !event.hasCompulsories && !event.hasBallet"> seule</span>

    <span v-if="event.hasBallet">Ballet</span>
    <span v-if="event.hasBallet && !event.hasCompulsories && !event.hasRoutine"> seul</span>
    <slot name="suffix">)</slot>
  </div>
</template>

<script>

export default {
  name: "EventComponentsList",
  props: { value : { type:Object, required: true }},
  computed: {
    event(){ return this.value; }
  }
}
</script>
